import {
    AnnounceInputs,
    CategoryInputs,
    EquipmentInputs,
    IAddress,
    IAnnounce,
    IAnnounceAd,
    IAnnounceAdReq,
    IAnnounceValidationDoccument,
    ICancellationPolicyType,
    IEquipment,
    IEquipmentCategory,
    ILocation,
    IPromotion,
    IProperty,
    IPropertyType,
    IPropertyTypeInputs,
    IReview,
    PromotionInputs,
    PropertyInputs,
    ReviewInputs,
    SearchInputs,
    ISearchType
} from "./AnnounceInterfaces";
import { IReservation, IReservationReq } from "./ReservationInterfaces";
import { IUser, IRole, RoleInputs } from "./UserInterfaces";
import { ArticleInputs, IArticle, IArticleBlog, IArticleCategory, IArticleTheme } from "./ArticleInterface";
import { EAnnounceStatus, ETarifs } from "./enum";
import { DestinationInputs, IPays } from "./DiversInterfaces";
import { IHotel } from "./HotelInterface";
import {
    DohonePayoutMode,
    IAnnounceAdPayment,
    IBookingPayment,
    IBookingPaymentOrange,
    IRefund,
    IRefundDohone,
    ITransaction,
    ITransactionReq,
} from "./PaymentInterfaces";
import { IAdParam } from "./SettingIntefaces";
import { EAdsPlacement } from "./enum/AdsPlacement";
import {
    ICommissionFee,
    ICommissionFeeInputs,
    IReservationFee,
    IReservationFeeInputs,
    IPaymentMethodFee,
    ISpecialFeeInputs,
} from "./FeeInterfaces";
import { ICurrency } from "./CurrencyInterface";
import moment from "moment";
import { EPaymentMethod } from "./enum/PaymentMethod";
import { ITouristGuide } from "../pages/Admin/Guide/services/GuideService";
import { tIPopup } from "../pages/Admin/Popup/services/PopupService";
import { VilleInputs } from "./DiversInterfaces";

export const defaultRoleValue: IRole = {
    _id: "",
    name: "",
};

export const defaultAnnounceStatus = [
    "Submitted",
    "Pending User",
    "Task Accepted",
    "Task Refused",
    "Verification Started",
    "Pending Validation",
    "Rejected",
    "Published",
    "Suspended",
    "Drafted",
];

export const defaultPropertieTypeName = [
    "Room",
    "Apartment",
    "House",
    "Hotel",
    "Suite",
    "Vehicle",
    "Experience"
];


/*
export const defaulTravellerValue: IUnregisteredTraveller = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    whatsappNumber: "",
    country: "",
    receptions: {
        paypal: [{
            _id: "",
            user: "",
            recepient_email: "",
            recepient_paypal_id: "",
            recepient_phone: "",
            }],
        mobileMoney: [{
            _id: "",
            user: "",
            mobile_operator: "",
            mobile_number: "",
            }]
    }
}
*/

export const defaultUserValue: IUser = {
    accountType: "",
    _id: "",
    token: "",
    firstName: "",
    lastName: "",
    country: "",
    gender: "Homme",
    birthDate: "",
    properties: [],
    favorites: [],
    phone: "",
    avatar: "",
    avatarURL: "",
    relationUser: "",
    phoneUrgent: "",
    nomUrgent: "",
    confirmedRegistration: false,
    confirmedCodeValidaton: false,
    registrationSmsToken: "",
    email: "",
    password: "",
    devenirHotel: false,
    role: defaultRoleValue,
    payments: {
        paypal: [],
        mobileMoney: [],
    },
    receptions: {
        paypal: [],
        mobileMoney: [],
    },
    lang: "",
    userID: "",
    credits: {
        paypal: 0,
        mobileMoney: 0,
    },
};

export const defaultPays: DestinationInputs = {
    _id: '',
    name: '',
    nomEN: '',
    nomFR: '',
    photo: '',
    countryId: '',
    registeredTaxName: '',
    taxReference: '',
    businessType: '',
    postalAdress: '',
    officeNumbers: 0,
    status: false,
    villes: [], // An empty array, as it's optional
    phoneCode: '',
    currencyCode: '',
};

export const defaultVilleInputs: VilleInputs = {
    _id: '',
    count: '0',
    name: '',
    nomFR: '',
    nomEN: '',
    quartiers: [],
};

export const villeDefaultValue: VilleInputs = {
    _id: '',
    count: '0',
    name: '',
    nomFR: '',
    nomEN: '',
    quartiers: [],
};

export const addressDefaultValue: IAddress = {
    street: "",
    city: {
        _id: "",
        name: "Default City",
        count: "0",
        nomFR: "Ville par défaut",
        nomEN: "Default City",
        quartiers: [],
    }, // Placeholder IVille object
    state: "",
    zipcode: "",
    country: {
        _id: "",
        name: "Default Country",
        nomFR: "Pays par défaut",
        nomEN: "Default Country",
        status: false,
        currencyCode: "",
        villes: [],
    }, // Placeholder IPays object
    quartier: "",
    lieu_dit: "",
};

export const currencyDefaultValue: ICurrency = {
    name: "",
    symbol: "",
    code: "",
};
export const locationDefaultValue: ILocation = {
    longitude: 0,
    latitude: 0,
};

export const propertyTypesDefaultValue: IPropertyType[] = [];

export const defaultSearchTypeValue: ISearchType = {
    _id: "",
    name: "",
    label: "",
    description: "", // Optional, you can omit this if you don't need a default value
};

export const propertyTypeDefaultValue: IPropertyType = {
    _id: "",
    name: "",
    searchType: defaultSearchTypeValue, // Use the default ISearchType object
};

export const propertyTypeInputsDefaultValue: IPropertyTypeInputs = {
    name: "",
    label: "",
    searchType: "",
    equipments: [], // Ensure this is the same as in the backend

}
export const equipmentsDefaultValue: IEquipment[] = [];

export const cancellationPolicyTypeDefaultValue: ICancellationPolicyType = {
    _id: "",
    name: "NANR (Non-annulable, non remboursable)",
};

export const locationTypesDefaultValue = [
    {
        _id: "",
        name: "",
    },
];

export const ITagDefaultValue = {
   _id: "",
 name: "",
  
};

export const IPartnerDefaultValue = {
    name: "",
    description: "",
    slug: "",
    website: "",
    contactEmail: "",
    logo: "",  // Default value for the logo file name
    bannerPicture: "",
};



export const equipmentCategoryInputsDefaultValue: CategoryInputs = {
    name: {
        en: "",  // English name input
        fr: "",  // French name input
    },
    description: {
        en: "",  // English description input
        fr: "",  // French description input
    },
    metaTitle: {
        en: "",  // English description input
        fr: "",  // French description input
    },
    applicableSearchTypes: [],
    categoryDropdown: "false",
};


// Default value for a single Equipment Category
export const equipmentCategoryDefaultValue: IEquipmentCategory = {
    _id: "",
    name: {
        en: "",  // English name
        fr: "",  // French name
    },
    description: {
        en: "",  // English description
        fr: "",  // French description
    },
};

export const articleCategoryDefaultValue: IArticleCategory = {
    _id: "",
    name: {
        en: "",  // English name
        fr: "",  // French name
        slug: ""
    },
    description: {
        en: "",  // English description
        fr: "",  // French description
    },
    metaTitle: {
        en: "",  // English description
        fr: "",  // French description
    },
    categoryDropdown: 'false'
};

export const articleThemeDefaultValue: IArticleTheme = {
    _id: "",
    name: {
        en: "",  // English name
        fr: "",  // French name
        slug: ""
    },
    description: {
        en: "",  // English description
        fr: "",  // French description
    },
    metaTitle: {
        en: "",  // English description
        fr: "",  // French description
    },
};

// Default value for an array of Equipment Categories
export const equipmentCategoriesDefaultValue: IEquipmentCategory[] = [];

export const articleCategoriesDefaultValue: IArticleCategory[] = [];



// Default value for a single Equipment
export const equipmentDefaultValue: IEquipment = {
    _id: "",
    name: {
        en: "",  // English name
        fr: "",  // French name
    },
    description: {
        en: "",  // English description
        fr: "",  // French description
    },
    category: equipmentCategoryDefaultValue,  // Default to an empty category
    applicableSearchTypes: [],  // Default to no search types
};

// Default value for Equipment input (used in forms)
export const equipmentInputDefaultValue: EquipmentInputs = {
    name: {
        en: "",  // English name input
        fr: "",  // French name input
    },
    description: {
        en: "",  // English description input
        fr: "",  // French description input
    },
    category: "",  // Default to no category selected
    applicableSearchTypes: [],  // Default to no search types selected
};

export const allUserinfosDefaultvalue: IUser = {
    _id: "",
    accountType: "",
    token: "",
    firstName: "",
    lastName: "",
    country: undefined,
    registrationSmsToken: "",
    gender: "Homme",
    birthDate: "",
    properties: [""],
    phone: "",
    avatar: "",
    relationUser: "",
    phoneUrgent: "",
    nomUrgent: "",
    confirmedRegistration: false,
    confirmedCodeValidaton: false,
    favorites: [],
    avatarURL: "",
    email: "",
    password: "",
    newPassword: "",
    confirmPassword: "",
    role: defaultRoleValue,
    payments: {
        paypal: [],
        mobileMoney: [],
    },
    receptions: {
        paypal: [],
        mobileMoney: [],
    },
    lang: "",
    userID: "",
    credits: {
        paypal: 0,
        mobileMoney: 0,
    },
};

export const propertyDefaultValue: IProperty = {
    _id: "",
    name: "",
    type: propertyTypeDefaultValue,
    maximumGuests: 0,
    address: addressDefaultValue,
    location: locationDefaultValue,
    rooms: "",
    owner: defaultUserValue,
    photos: [],
    confirmed: false,
    equipments: equipmentsDefaultValue,
    hotel: "",
    typeChambre: "",
    reglement: "",
    quartier: "",
    superficie: "",
    lit: 0,
    bathrooms: 0,
    toilette: 0,
    jardin: "",
};



export const propertyInputsDefaultValue: PropertyInputs = {
    name: "",
    type: null,
    maximumGuests: 0,
    address: addressDefaultValue,
    location: locationDefaultValue,
    rooms: "",
    owner: "",
    photos: [],
    confirmed: false,
    equipments: [],
    hotel: null,
    typeChambre: "",
    reglement: "",
    quartier: "",
    superficie: "",
    lit: 0,
    bathrooms: 0,
    toilette: 0,
    jardin: "",
    destinations: []
};

/*
export const bookerTravellerDefaultValue: IUnregisteredTravellerDetailsForm = {
    bookerFirstName: "",
    bookerLastName: "",
    bookerEmail: "",
    bookerPhone: "",
    bookerWhatsappNumber: "",
    bookerPays: "",
    travellerFirstName: "",
    travellerLastName: "",
    travellerEmail: "",
    travellerPhone:"",
    travellerWhatsappNumber:"",
    travellerPays: "",
}
*/

export const announceDefaultValue: IAnnounce = {
    _id: "",
    announceValidation: null,
    description: "",
    priceOutOfCity: 0,
    price: 0,
    priceWeekly: 0,
    priceBiWeekly: 0,
    priceMonthly: 0,
    publishDate: new Date(),
    cancellationPolicy: "",
    state: EAnnounceStatus.Submitted,
    superOffer: 0,
    property: propertyDefaultValue,
    owner: defaultUserValue,
    note: 0,
    promotion: null,
    rules: "",
    cleaningFees: 0,
    visibleReviews: 0,
    freeRefundDay: 3,
    penaltyDayFees: 0,
    penaltyDayFeesAfterBookingStarted: 0,
    account_paypal: "",
    account_orange: "",
    account_nexttel: "",
    account_express_union: "",
    account_yup: "",
    account_mtn: "",
    devise: "",
    animaux: false,
    enfant: false,
    fetes: false,
    reservation: [],
    checkin: moment(),
    checkout: moment(),
    codePhone: "",
    _idRef: "",
    contactName: "",
    contactPhone: "",
    howToAccessDesc: "",
    sponsorshipCode: "",
    favoritePaymentMethod: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    activeStep: 0,
    ambassador: false,
    dateIndisponible: [],
    notRemboursable1: 0,
    notRemboursable2: 0,
    remboursable: "",
    slug: "",
    score: 0,
    rank: 0
};

export const announceInputsDefaultValue: AnnounceInputs = {
    note: 0,
    visibleReviews: 0,
    description: "",
    priceOutOfCity: 0,
    price: 0,
    priceWeekly: 0,
    priceBiWeekly: 0,
    priceMonthly: 0,
    publishDate: new Date(),
    cancellationPolicy: "",
    state: EAnnounceStatus.Submitted,
    superOffer: 0,
    property: "",
    owner: "",
    rules: "",
    announceValidation: null,
    promotion: null,
    cleaningFees: 0,
    freeRefundDay: 3,
    penaltyDayFees: 1,
    penaltyDayFeesAfterBookingStarted: 0,
    account_paypal: "",
    account_orange: "",
    account_nexttel: "",
    account_express_union: "",
    account_yup: "",
    account_mtn: "",
    devise: "",
    animaux: false,
    enfant: false,
    fetes: false,
    reservation: [],
    checkin: moment(),
    checkout: moment(),
    codePhone: "",
    _idRef: "",
    contactName: "",
    contactPhone: "",
    howToAccessDesc: "",
    sponsorshipCode: "",
    favoritePaymentMethod: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    activeStep: 0,
    ambassador: false,
    dateIndisponible: [],
    notRemboursable1: 0,
    notRemboursable2: 0,
    remboursable: "",
    slug: "",
};

export const validationAnnounceDefaultValue: IAnnounceValidationDoccument = {
    announce: announceDefaultValue,
    description: "",
    equipments: "",
    hygiene: "",
    security: "",
    address: "",
    photos: "",
    location: "",
    messages: [],
    isSubmitted: false,
    user: defaultUserValue,
    email: "",
    phone: "",
    realPhotos: [],
    report: "",
    /*uptade type de logement*/
    luxury: false,
    perle: false,
    business: false,
    //standard: false,
    //prestige: false,
    //premium: false,
};

export const searchDefaultValue: SearchInputs = {
    country: "",
    rooms: "",
    location: [""],
    city: "",
    quartier: "",
    typeProperty: "",
    depart: "",
    arrive: "",
    nbPersone: 0,
    price: 0,
    priceMin: 0,
};

export const reservationDefaultValue: IReservation = {
    dateCheckIn: "",
    dateCheckOut: "",
    price: 0,
    status: [],
    travellerAdultTotal: 0,
    travellerChildTotal: 0,
    announce: announceDefaultValue,
    traveller: defaultUserValue,
    tarif: ETarifs.JOURNALIER,
    host: defaultUserValue,
    attachments: [],
    services: [],
    archive: false,
    canceled: false,
    creditPaye: 0,
};

export const reviewDefaultValue: IReview = {
    _id: "",
    description: "",
    note: 0,
    reviewDate: new Date(),
    user: defaultUserValue,  // Corrected to use IUser default
    announce: announceDefaultValue,  // Corrected to use IAnnounce default
    reservation: undefined,
    countReview: true,
    isVisible: true,
    isHidden: false,
    visibleAuthor: "",
    visibleDate: undefined,
    cleanliness: 0,
    accuracy: 0,
    communication: 0,
    location: 0,
    checkin: 0,
    value: 0,
    vehiclecondition: 0,
    customerservice: 0,
    easeofbookingandpickup: 0,
    valueformoney: 0,
};

export const reviewInputDefaultValue: ReviewInputs = {
    description: "",
    note: null, // Matches IReview definition
    reviewDate: new Date(),
    hotel: undefined, // Optional fields set to undefined
    user: defaultUserValue,  // Default IUser value
    announce: announceDefaultValue,  // Default IAnnounce value
    reservation: undefined, // Optional field set to undefined
    countReview: true,
    isVisible: true,
    isHidden: false,
    visibleAuthor: "",
    visibleDate: undefined,
    commentTitle: "",

    // Fields for searchType === "apts" or "htls"
    cleanliness: null,
    accuracy: null,
    communication: null,
    location: null,
    checkin: null,
    value: null,

    // Fields for searchType === "cars"
    vehiclecondition: null,
    customerservice: null,
    easeofbookingandpickup: null,
    valueformoney: null,

    // Fields for searchType === "trs"
    foodquality: null,
    servicequality: null,
    ambiance: null,
    drinkquality: null,
    comfort: null,
    entertainmentactivities: null,
    historicalsignificance: null,
    exhibitsquality: null,

    // Common fields
    experienceDate: undefined,
    isCertified: false,
    pictures: [],
};

export const articleInputDefaultValue: ArticleInputs = {
    _id: "",
    title: "",
    content: "",
    author: "",
    photo: "",
    category: {
        _id: "",  // Providing an empty default object for IEquipmentCategory
        name: { en: "", fr: "", slug: "" },  // Assuming `name` is a localized object
        categoryDropdown: "",
    },
    tags: [],
    urlshort: "",
    slug: "",
    theme: {
        _id: "",  // Providing an empty default object for IEquipmentCategory
        name: { en: "", fr: "", slug:"" },  // Assuming `name` is a localized object
    },
};



export const articleDefaultValue: IArticle = {
    _id: "",
    title: "",
    introduction: "",
    content: "",
    photo: "",
    photoAltText: "",
    photoLegend: "",
    author: defaultUserValue,
    category: articleCategoryDefaultValue,  // Providing a default object for category
    theme: articleThemeDefaultValue,  // Providing a default object for theme
    tags: [ITagDefaultValue],  // Assuming the default tag object
    updatedAt: null,
    urlshort: "",
    metaDescription: "",
    keywords: "",
    slug: "",
    ogImage: "",
    youtubeUrl: "",
    breadcrumb1Url: "",
    breadcrumb1Text: "",
    breadcrumb2Url: "",
    breadcrumb2Text: "",
    ctaText: "",
    ctaLink: "",
    likes: [],
    views: 0,
    comments: [],
    internalLinks: [],
    externalLinks: [],
    reference: "",
    internalComment: "",
    status: "Draft",  // Assuming a default status, adjust if needed
    reviewDate: null,
    createdAt: new Date(),
    videoTitle: "",
    videoDescription: "",
    videoThumbnail: "",
    videoUploadDate: null,
    videoDuration: "",
    businessType: "",
    businessName: "",
    country: "",
    city: "",
    videoLegend: "",
    ctaButtonText: "",
    visibleAuthor: "",
    allowComments: true,  // Assuming default allowComments to be true
};

export const articleBlogDefaultValue: IArticleBlog = {
    _id: "",
    title: "",
    introduction: "",
    photo: "",
    photoAltText: "",
    photoLegend: "",
    author: defaultUserValue,  // Assuming defaultUserValue is predefined
    category: articleCategoryDefaultValue,  // Assuming articleCategoryDefaultValue is predefined
    theme: articleThemeDefaultValue,  // Assuming articleThemeDefaultValue is predefined
    updatedAt: null,
    likes: [],
    views: 0,
    status: "Draft",  // Default status set to Draft
    createdAt: new Date(),  // Set to the current date by default
    visibleAuthor: "",  // Empty by default
    slug: ""
};



export const defaultVilleInput: any = {
    name: "",
};
export const DestinationInputDefaultValue: DestinationInputs = {
    _id: "", // Add a default or placeholder value for _id
    name: "",
    photo: "",
    countryId: "",
    registeredTaxName: "",
    taxReference: "",
    businessType: "",
    postalAdress: "",
    officeNumbers: 0,
    status: false,
    villes: [defaultVilleInput],
    phoneCode: "",
    nomFR: "",
    nomEN: "",
    currencyCode: "",
};

export const roleInputsDefaultValue: RoleInputs = {
    name: "",
};

export const roleDefaultValue: IRole = {
    _id: "",
    name: "",
};
export const HotelInputDefaultValue: IHotel = {
    admin: defaultUserValue,
    companyName: "",
    companyLogo: "",
    stars: 0,
    phone: "",
    properties: [propertyDefaultValue],
    photos: [],
    adresse: "",
    proprietaire: "",
    gerant: "",
    coordonne: "",
    description: "",
    location: locationDefaultValue,
    address: addressDefaultValue,
    rooms: 0,
    phoneUrgent: "",
    numberPhoneUrgent: "",
    phoneUrgentProp: "",
    codephoneurgentProp: "",
    paysProp: "",
    paysgerant: "",
    codephoneurgent: "",
    typeLogement: "",
};

export const promotionDefaultValue: IPromotion = {
    title: "",
    startDate: null,
    endDate: null,
    price: 0,
    discount: 0,
    announce: announceDefaultValue,
};

export const promotionInputsDefaultValue: PromotionInputs = {
    title: "",
    startDate: null,
    endDate: null,
    price: 0,
    discount: 10,
    announce: "",
};

export const paymentDefaultValue: IBookingPayment = {
    method: "",
    transaction: {
        items: {
            booking: reservationDefaultValue,
            services: [],
        },
        amount: {
            discount: 0,
            total: 0,
        },
    },
    description: "",
};

export const paymentOrangeDefaultValue: IBookingPaymentOrange = {
    method: "",
    transaction: {
        items: {
            booking: reservationDefaultValue,
            services: [],
        },
        amount: {
            discount: 0,
            total: 0,
        },
    },
    description: "",
    access_token: "",
    lang: "fr",
    return_url: "",
    cancel_url: "",
    notif_url: "",
};

export const annnonceAdPaymentDefaultValue: IAnnounceAdPayment = {
    method: EPaymentMethod.Paypal,
    transaction: {
        items: {
            announce: "",
            adParam: "",
        },
        amount: {
            discount: 0,
            total: 0,
        },
    },
    description: "",
};
export const transactionDefaultValue: ITransaction = {
    method: "",
    billingToken: null,
    facilitatorAccessToken: "",
    orderID: "",
    payerID: "",
    paymentID: null,
    link: "",
    paymentEmail: "",
    reservation: "",
    announceAd: "",
    type: "",
};

export const adsParamDefaultValue: IAdParam = {
    placement: EAdsPlacement.SearchPage,
    duration: 0,
    price: 0,
};

export const announceAdDefaultValue: IAnnounceAd = {
    announce: "",
    adParam: "",
    startAt: null,
    state: true,
};

export const announceAdReqDefaultValue: IAnnounceAdReq = {
    announce: announceDefaultValue,
    adParam: adsParamDefaultValue,
    startAt: null,
    state: true,
};
export const reservationReqDefaultValue: IReservationReq = {
    dateCheckIn: "",
    dateCheckOut: "",
    price: 0,
    status: [],
    travellerAdultTotal: 0,
    travellerChildTotal: 0,
    announce: announceDefaultValue,
    promotion: promotionDefaultValue,
    traveller: defaultUserValue,
    tarif: ETarifs.JOURNALIER,
    host: defaultUserValue,
    attachments: [],
    services: [],
    archive: false,
    canceled: false,
    incrimentationJour: 0,
};

export const transactionReqDefaultValue: ITransactionReq = {
    _id: "",
    method: "",
    billingToken: null,
    facilitatorAccessToken: "",
    orderID: "",
    payerID: "",
    paymentID: null,
    link: "",
    paymentEmail: "",
    reservation: reservationReqDefaultValue,
    announceAd: announceAdReqDefaultValue,
    type: "",
};

export const paysDefaultValue: IPays = {
    _id: "", // Placeholder value for _id
    name: "",
    photo: "",
    countryId: "",
    registeredTaxName: "",
    taxReference: "",
    businessType: "",
    postalAdress: "",
    officeNumbers: 0,
    status: false,
    villes: [],
    phoneCode: "",
    nomFR: "",
    nomEN: "",
    currencyCode: "",
};


export const commissionFeeDefaultValue: ICommissionFee = {
    country: paysDefaultValue,
    city: "",
    percentage: 0,
};

export const commissionFeeInputsDefaultValue: ICommissionFeeInputs = {
    country: "",
    city: "",
    percentage: 0,
};

export const reservationFeeDefaultValue: IReservationFee = {
    country: paysDefaultValue,
    percentage: 0,
};

export const reservationFeeInputsDefaultValue: IReservationFeeInputs = {
    country: "",
    percentage: 0,
};

export const paymentMethodDefaultvalue: IPaymentMethodFee = {
    type: "",
    percentage: 0,
};

export const specialFeesDefaultValue: ISpecialFeeInputs = {
    propertyType: "",
    percentage: 0,
    percentageTraveller: 0,
};

export const refundTravellerDefaultValue: IRefund = {
    email_subject: "AfriqPnB Web, Remboursement !",
    email_message:
        "Suite à votre demande, ci-présent donc notre message pour vous confirmé que le remboursement a été efectué",
    recepient_type: "",
    amount: {
        value: 0,
        currency: "USD",
    },
    note: "Vous venez de recevoir un remboursement",
    reservation: "",
    receiver: "",
    userId: null,
};

export const refundHostDefaultValue: IRefund = {
    email_subject: "AfriqPnB Web, Commission !",
    email_message:
        "Suite à un demande de rembousement par l'un de vos clients, ci-présent donc notre message pour vous confirmé l'envoi de votre commission qui vous est dù",
    recepient_type: "",
    amount: {
        value: 0,
        currency: "USD",
    },
    note: "Vous venez de recevoir une commission suite à une demande de remboursement",
    reservation: "",
    receiver: "",
    userId: null,
};

export const dohoneRefundTravellerDefaultValue: IRefundDohone = {
    destination: "",
    mode: DohonePayoutMode.orangeMoney,
    amount: 0,
    devise: "XAF",
    nameDest: "",
    ville: "",
    pays: "",
    rUserId: "",
    reservation: "",
    userId: null,
};

export const guideDefaultValue: ITouristGuide = {
    preview: "",
    file: null,
    title: "",
    description: "",
};

export const popupDefaultValue: tIPopup = {
    alt: "",
    title: "",
    image: "",
    imageMobile: "",
    description: "",
};
