import Api from "../../../../https/Api";
export interface IUserAuth {
    email: string;
    password: string;
}

export interface IUserAuthResponse {
    email: string;
    password: string;
    role: string;
    token: string;
}

export class LoginService {
    private api;
    constructor() {
        this.api = new Api();
    }
    async authentificate(user: IUserAuth) {
        return this.api.post("users/authenticates", user);
    }
    async authenticateWithGoogle(credential: string): Promise<{ success: boolean; user: any }> {
        return this.api.post("auth/google", { credential });
    }
    
    // New method to check if a user exists by email
    async checkUserByEmail(email: string) {
        return this.api.get(`users/check-email/${email}`);
    }
}
