import {
    makeStyles,
    createStyles,
    TextField,
    InputAdornment,
    Typography,
    IconButton,
    Link,
} from "@material-ui/core";
import React, { useContext } from "react";
import PersonOutlinedIcon from "@material-ui/icons/PersonOutlined";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";
import Button from "@material-ui/core/Button";
import SessionHandler from "../../../https/SessionHandler";
import { LoginService, IUserAuth } from "./services/LoginService";
import { useHistory } from "react-router-dom";
import Spin from "../../../images/Spin";
import { Error, Visibility, VisibilityOff } from "@material-ui/icons";
import { colors } from "../../../styles/defaultColors";
import {
    getErrorLabel,
    UserErrorMessage,
    UserErrorMessageFromServer,
} from "../../../utils/UserInterfaces";
import { GeneratePasswordCodeDialog } from "../ResetPassword/GeneratePasswordCodeDialog";
import { setTimeout } from "timers";
import { UserContext } from "../../../contexts/AppContext";
import { useTranslation, Trans } from "react-i18next";
import { ERoles } from "../../../utils/enum";
import { RouteAdmin } from "../../Admin/constant/routesName";
import i18n from "../../../i18n/config";
import { useQuery } from "../../../utils/App";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { IUserRegister } from "../Register/services/RegisterService";
import { RegisterService } from "../Register/services/RegisterService";
import jwt from 'jsonwebtoken';
import { JwtPayload } from 'jsonwebtoken';

const useStyles = makeStyles((theme) =>
    createStyles({
        formLoginContainer: {
            height: "auto",
            marginTop: 20,
        },
        inputForm: {
            display: "flex",
            marginBottom: theme.spacing(2),
        },
        forgotPassword: {
            textAlign: "right",
            textDecoration: "none",
            marginBottom: theme.spacing(2),
        },
        register: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            margin: "40px auto 20px auto",
        },
        registerLink: {
            color: "#EA7606",
        },
        link: {
            color: "#B5551B", // Set the color for both links
            textDecoration: "none",
            fontFamily: "Josefin Sans",
            fontSize: 14,
            textTransform: "none",
            "&:hover": {
                textDecoration: "underline", // Add underline on hover
            },
        },
        error: {
            color: colors.danger,
            background: colors.backgroundDanger,
            width: "100%",
            textAlign: "center",
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            borderRadius: 5,
            "& p": {
                padding: 10,
            },
        },
        buttonLink: {
            textAlign: "right",
            textDecoration: "none",
            color: "#7D7D57",
            textTransform: "none",
            fontFamily: "Josefin Sans",
            fontSize: 14,
        },
        buttons: {
            marginBottom: theme.spacing(2),
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
        },
        loginButton: {
            width: "100%",
            background: "#890608",
            color: "white",
            borderRadius: 100,
            fontFamily: "Josefin Sans",
            fontSize: 14,
            textTransform: "none",
            padding: "8px 14px",
            "&:hover": {
                background: "#B5551B",
            },
        },
        textField: {
            fontFamily: "Josefin Sans",
            fontSize: 14,
            color: "#000000",
        },
        buttonLinkSing: {
            color: "#EA7606",
            fontFamily: "Josefin Sans",
            fontSize: 16,
            textTransform: "none",
        },
        notMember: {
            fontFamily: "Josefin Sans",
            fontSize: 16,
        },
        icon: {
            position: "relative",
            top: -4,
        },
        divider: {
            width: 140,
            height: 1,
            background: "#EBEBEB",
            margin: "40px auto",
        },
        dividerWithText: {
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            margin: "10px auto",
            width: "100%",
            "&::before, &::after": {
                content: '""',
                flex: 1,
                borderBottom: "1px solid #EBEBEB",
            },
            "&::before": {
                marginRight: theme.spacing(2),
            },
            "&::after": {
                marginLeft: theme.spacing(2),
            },
        },
        footerText: {
            textAlign: "center",
            marginTop: theme.spacing(4),
            fontSize: "0.875rem",
            color: theme.palette.text.secondary,
        },
        links: {
            textDecoration: "underline",
            color: theme.palette.text.secondary,
        },
        bottomLinks: {
            display: "flex",
            justifyContent: "space-between", // Space between "Forgot password?" and "Sign Up"
            alignItems: "center",
            width: "100%",
            marginTop: theme.spacing(1), // Add spacing below the sign-in button
        },
        header: {
            textAlign: "center",
            marginBottom: theme.spacing(4),
            fontSize: "1rem",
            color: theme.palette.text.primary,
            fontFamily: "Josefin Sans",
        },
        "@global": {
            ".MuiInputBase-input": {
                fontFamily: "Josefin Sans",
                fontSize: 14,
                color: "#000000",
            },
            ".MuiInputBase-input::placeholder": {
                opacity: 0.7,
            },
        },
    })
);

interface GoogleDecodedToken extends JwtPayload {
    email?: string;
    name?: string;
    picture?: string;
    locale?: string;
}

type DecodedToken = GoogleDecodedToken | string | null;

const registerService = new RegisterService();
const loginService = new LoginService();
export function LoginForm(props: {
    handleClose: () => void;
    handleClickOpenRegister: () => void;
    onLoginSuccess?: (user: any) => void;
    redirectPath?: string;
}) {

    const { redirectPath } = props;
    let query = useQuery();
    const classes = useStyles();
    const { t } = useTranslation();
    const [values, setValues] = React.useState({
        email: "",
        password: "",
        lang: "FR",
        showPassword: false,
        remember_me: false,
    });
    const [error, setError] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const history = useHistory();

    const sessionHandler = React.useMemo(() => new SessionHandler(), []);
    const userContext = useContext(UserContext);
    const { setUser } = userContext;

    const [openResetDialog, setOpenResetDialog] = React.useState(false);

    const [handlePassword, setHandlePassword] = React.useState({
        password: "",
        showPassword: false,
    });
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

    const handleClickShowPassword = () => {
        setHandlePassword({
            ...handlePassword,
            showPassword: !handlePassword.showPassword,
        });
    };

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const handleOpenResetDialog = () => {
        setOpenResetDialog(true);
    };

    const handleCloseResetDialog = () => {
        setOpenResetDialog(false);
    };

    const handlOpenRegister = () => {
        props.handleClickOpenRegister();
    };

    const handleChange = (prop: any) => (event: any) => {
        setError("");
        setValues({
            ...values,
            [prop]:
                prop === "password"
                    ? event.target.value
                    : event.target.value.toLowerCase(),
        });
    };

    const handleSubmit = (evt: any) => {
        evt.preventDefault();
        setError("");

        if (!values.email) {
            setError("EMAIL");
            return;
        }

        if (!values.password) {
            setError("PWD");
            return;
        }

        setLoading(true);
        const userAuth: IUserAuth = {
            email: values.email,
            password: values.password,
        };
        loginService
            .authentificate(userAuth)
            .then((response: any) => {
                if (response.success) {
                    const { user } = response;
                    sessionHandler.saveAccessToken(user.token);
                    sessionHandler.saveRefreshToken(user.refreshToken);
                    setUser(user.data);

                    if (user.data.lang) {
                        i18n.changeLanguage(user.data.lang.toLowerCase());
                    }

                    // Call the onLoginSuccess prop if provided
                    if (props.onLoginSuccess) {
                        props.onLoginSuccess(user.data._id);
                    }
                    // Redirect to the provided path or home page
                    history.push(redirectPath || "/");
                    props.handleClose();

                    /*if (
                        user.data.role.name === ERoles.SUPER_ADMIN ||
                        user.data.role.name === ERoles.ADMIN_SUPPORT ||
                        user.data.role.name === ERoles.SALES_AGENT ||
                        user.data.role.name === ERoles.COUNTRY_ADMIN
                    ) {
                        history.push(RouteAdmin.ACTIVITY_ANNOUNCE);
                    } else {
                        if (query.get("redirectPage")) {
                            history.push(`/${query.get("redirectPage")}`);
                        } 
                    }
                    props.handleClose();*/
                } else {
                    setError("ACCOUNT");
                }
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            })
            .catch((err: any) => {
                if (err.error === UserErrorMessageFromServer.NotFound) {
                    setError("ACCOUNT");
                } else if (
                    err.error === UserErrorMessageFromServer.WrongPassword
                ) {
                    setError("ACCOUNT");
                } else if (
                    err.error === UserErrorMessageFromServer.UnConfirmedAccount
                ) {
                    setError("UNCONFIRM ACCOUNT");
                } else {
                    setError("ACCOUNT");
                }
                setLoading(false);
            });
    };

    const GUEST_ROLE_ID = "61dd2acfe35a732c05ea25a0"; // ObjectId for the 'guest' role

    // Handle Google Login Success
    const handleGoogleLoginSuccess = async (credentialResponse: any) => {
        try {
            setLoading(true);
            const { credential } = credentialResponse;
    
            // Log the credential received from Google
            console.log("Step 1: Google Credential Received:", credential);
    
            if (!credential) {
                console.error("Step 1: Invalid Google credential.");
                setErrorMessage("Invalid Google credential.");
                return;
            }
    
            // Decode the Google credential
            const decodedToken = jwt.decode(credential) as GoogleDecodedToken | null;
            console.log("Step 2: Decoded Token:", decodedToken);
    
            if (!decodedToken || typeof decodedToken === "string") {
                console.error("Step 2: Invalid or malformed Google credential.");
                setErrorMessage("Invalid Google credential.");
                return;
            }
    
            // Extract user details from the decoded token
            const googleEmail = decodedToken.email;
            const googleGivenName = decodedToken.given_name; // Extract given name
            const googleFamilyName = decodedToken.family_name; // Extract family name
            const googlePicture = decodedToken.picture;
            const googleLanguage = decodedToken.locale || "FR";
                
            console.log("Step 3: Extracted User Details:", {
                googleEmail,
                googleGivenName,
                googleFamilyName,
                googlePicture,
                googleLanguage,
            });
    
            if (!googleEmail || (!googleGivenName && !googleFamilyName)) {
                console.error("Step 3: Missing required fields in Google credential.");
                setErrorMessage("Failed to decode Google credential.");
                return;
            }
    
            // Step 4: Check if the user already exists
            console.log("Step 4: Checking if user exists with email:", googleEmail);
            const userExistsResponse = await checkIfUserExists(googleEmail);
            const userExists = userExistsResponse.exists;
            let userId = userExistsResponse.userId; // Get the user ID from the response
            console.log("Step 4: User Exists:", userExists);
    
            if (!userExists) {
                console.log("Step 5: User does not exist. Creating new user...");
    
                // Step 5: Create a new user using Google user data
                const newUser: IUserRegister = {
                    firstName: googleGivenName, // Use given_name for first name
                    lastName: googleFamilyName, // Use family_name for last name
                    email: googleEmail,
                    password: "@defaultPassword1", // You can generate a random password or leave it empty
                    confirmPassword: "@defaultPassword1",
                    birthDate: new Date().toISOString(), // Optional: Set a default birth date
                    country: "DefaultCountry", // Optional: Set a default country
                    role: GUEST_ROLE_ID, // Default role for reviewers
                    gender: "male", // Optional: Set a default gender
                    avatar: googlePicture, // Use Google profile picture
                    phone: "", // Optional: Set a default phone number
                    numberPhoneUrgent: "",
                    numberPhone: "",
                    countryUrgent: "",
                    relationUser: "",
                    nomUrgent: "",
                    phoneUrgent: "",
                    lang: googleLanguage, // Use Google language
                    userSource: "Google",
                };
    
                console.log("Step 5: New User Data:", newUser);
    
                // Register the new user
                const registrationResponse = await registerService.register(newUser);
                console.log("Step 5: User Registration Response:", registrationResponse);

                // Update the user context with the newly created user
                if (registrationResponse.success) {
                    setUser(registrationResponse.user); // Update the user context
                    userId = registrationResponse.user._id; // Get the user ID of the newly created user
                }
            }
    
            // Step 6: Log the user in
            // Log the user in
            console.log("Step 6: Logging in the user with Google credential...");
            const loginResponse = await loginService.authenticateWithGoogle(credential);
            console.log("Step 6: Login Response:", loginResponse);

            if (loginResponse.success) {
                const { user } = loginResponse;
                console.log("Step 6: Logged-in User Data:", user);

                // Update the user context with the logged-in user
                setUser(user.data);

                // Call the onLoginSuccess prop if provided
                if (props.onLoginSuccess) {
                    props.onLoginSuccess(user.data._id);
                }

                // Redirect to the provided path or home page
                console.log("Step 6: Redirecting to:", redirectPath || "/");
                history.push(redirectPath || "/");
                props.handleClose();
            } else {
                console.error("Step 6: Login failed. Response:", loginResponse);
                setError("ACCOUNT");
            }
        } catch (err) {
            console.error("Error during Google login:", err);
            setError("ACCOUNT");
        } finally {
            setLoading(false);
        }
    };

    const checkIfUserExists = async (email: string): Promise<{ exists: boolean; userId?: string }> => {
        try {
            // Call the backend API to check if the user exists
            const response = await loginService.checkUserByEmail(email);
    
            // Ensure the response has the expected structure
            if (response && typeof response.success === "boolean" && typeof response.exists === "boolean") {
                return {
                    exists: response.exists,
                    userId: response.userId || undefined, // Return the user ID if it exists
                };
            } else {
                console.error("Invalid response structure from backend:", response);
                return { exists: false };
            }
        } catch (error) {
            console.error("Error checking if user exists:", error);
            return { exists: false };
        }
    };

    const handleGoogleLoginFailure = () => {
        setError("GOOGLE_LOGIN_FAILED");
    };

    return (
        <form
            onSubmit={handleSubmit}
            autoComplete="off"
            className={classes.formLoginContainer}
        >
            {/*<Typography variant="body1" className={classes.header}>
                {t("SignIn.signInMessage")}
            </Typography> */}
            <div className={classes.inputForm}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="input-email-with-icon-textfield"
                    type="email"
                    fullWidth
                    variant="outlined"
                    label="Email"
                    onChange={handleChange("email")}
                    classes={{ root: classes.textField }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PersonOutlinedIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <div className={classes.inputForm}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="input-password-with-icon-textfield"
                    type={handlePassword.showPassword ? "text" : "password"}
                    variant="outlined"
                    fullWidth
                    value={values.password}
                    onChange={handleChange("password")}
                    label={t("SignIn.password")}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <VpnKeyOutlinedIcon className={classes.icon} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {handlePassword.showPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>

            {error && (
                <div className={classes.error}>
                    <Typography>
                        <Error />
                        <br />
                        {error === "EMAIL" &&
                            getErrorLabel(UserErrorMessage.EmptyEmail)}{" "}
                        {error === "EMAIL WRONG" &&
                            getErrorLabel(UserErrorMessage.WrongPassword)}{" "}
                        {error === "PWD" &&
                            getErrorLabel(UserErrorMessage.EmptyPassword)}
                        {error === "PWD WRONG" &&
                            getErrorLabel(UserErrorMessage.WrongPassword)}
                        {error === "ACCOUNT" &&
                            getErrorLabel(UserErrorMessage.NotFound)}{" "}
                        {error === "UNCONFIRM ACCOUNT" &&
                            getErrorLabel(
                                UserErrorMessage.UnConfrimAccount
                            )}{" "}
                    </Typography>
                </div>
            )}

            <div className={classes.buttons}>
                <Button
                    type="submit"
                    endIcon={loading ? <Spin /> : null}
                    disabled={loading}
                    className={classes.loginButton}
                >
                    {t("SignIn.button")}
                </Button>

                <div className={classes.bottomLinks}>
                    <div className={classes.forgotPassword}>
                        <Button
                            className={classes.link}
                            onClick={handleOpenResetDialog}
                        >
                            {t("SignIn.passforgot")}
                        </Button>
                        <GeneratePasswordCodeDialog
                            open={openResetDialog}
                            handleClose={handleCloseResetDialog}
                        />
                    </div>

                    <div >
                        <Button
                            className={classes.link}
                            onClick={handlOpenRegister}
                        >
                            {t("SignIn.signup")}
                        </Button>
                    </div>
                </div>
            </div>          

            <div className={classes.dividerWithText}>
                {t("SignIn.or")}
            </div>

            
            {/* Google Login Button */}
            <div
                style={{
                    marginBottom: "20px",
                    border: "4px solid rgb(74, 75, 76)", // Thicker custom border
                    display: "block", // Changed from 'inline-block' to 'block'
                    width: "100%", 
                    borderRadius: "8px", // Optional: rounded corners
                }}
            >
                <GoogleLogin
                    onSuccess={handleGoogleLoginSuccess}
                    onError={handleGoogleLoginFailure}
                    useOneTap
                    text="signin_with"
                    shape="rectangular"
                    size="large"
                    width="100%"
                />
            </div>
            <Typography variant="body2" className={classes.footerText}  style={{
                    marginBottom: "20px" }} >
                <Trans
                    i18nKey="footer.agreement"
                    components={{
                    1: (
                        <Link
                        href="https://afriqpnb.com/footer/gcu"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.links}
                        />
                    ),
                    3: (
                        <Link
                        href="https://afriqpnb.com/footer/dpp"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.links}
                        />
                    )
                    }}
                />
            </Typography>
        </form>
    );
}
