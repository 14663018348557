import {
    makeStyles,
    createStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
} from "@material-ui/core";
import React from "react";
import { LoginForm } from "./LoginForm";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) =>
    createStyles({
        paper: {
            minWidth: 400,
            maxWidth: 500,
        },
        title: {
            borderBottom: "1px solid #EBEBEB",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "Josefin Sans",
            fontWeight: 600,
            color: "#000000",
            fontSize: 20,
        },
        close: {
            padding: 5,
            position: "absolute",
            right: 20,
        },
    })
);

export function LoginDialog(props: {
    handleClose: () => void;
    open: boolean;
    handleClickOpenRegister: () => void;
    redirectState?: any; // Pass the review form state here
    redirectPath?: string;
    onLoginSuccess?: (userId: string) => void; // Add this prop to pass userId back
}) {
    const classes = useStyles();
    const { t } = useTranslation();

    const { redirectState, redirectPath } = props;

    const handleLoginSuccess = (userId: string) => {
        if (props.onLoginSuccess) {
            props.onLoginSuccess(userId); // Pass the userId back to DetailAnnounce
        }

        if (redirectState) {
            // Redirect back to the review form with the saved state
            props.handleClose();
            // You can use a callback or context to restore the state in the review form
        } else {
            // Default behavior
            props.handleClose();
        }
    };

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            classes={{ paper: classes.paper }}
        >
            <DialogTitle classes={{ root: classes.title }}>
                <IconButton
                    onClick={props.handleClose}
                    className={classes.close}
                >
                    <CloseIcon />
                </IconButton>
                {t("SignIn.title")}
            </DialogTitle>
            <DialogContent>
                <LoginForm
                    handleClose={props.handleClose}
                    handleClickOpenRegister={props.handleClickOpenRegister}
                    onLoginSuccess={handleLoginSuccess} // Pass the success handler
                    redirectPath={redirectPath}
                />
            </DialogContent>
        </Dialog>
    );
}
